import React from "react"
import { Container } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const Footer = () => {
  const { logo } = useStaticQuery(
    graphql`
      query {
        logo: file(relativePath: { eq: "logo_white.png" }) {
          childImageSharp {
            fluid(maxWidth: 350, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <footer className="pt-5 mt-5 bg-blue">
      <Container fluid>
        <div className="row">
          <div className="col-md-4">
            <Image fluid={logo.childImageSharp.fluid} alt="Hanseatisches Immobilienkontor Schwolle &amp; Gienke GmbH &amp; Co. KG" />
          </div>
          <div className="col-md-4 mt-5 mt-md-0">
            <h4 className="h6">Hanseatisches Immobilienkontor Schwolle &amp; Gienke GmbH &amp; Co. KG</h4>
            <p>
              Mit uns zeigt sich Ihre Immobilie von ihrer besten Seite! Wir erstellen hochwertige Exposés und geben Ihnen fundierte Tipps, wie Sie Ihre Immobilie auch vor Ort perfekt in Szene setzen. Wir arrangieren Besichtigungen und beantworten bei Führungen fachkundig alle Fragen der Interessenten.
            </p>
          </div>
          <div className="col-md-4 mt-5 mt-md-0">
            <h4 className="h6">Standort Bendestorf</h4>
            <p>
              Poststr. 11<br/>
              21227 Bendestorf<br/>
              Tel.: 04183 77 50 35
            </p>
          </div>
        </div>
      </Container>
      <div className="bottomBar mt-5 py-2 text-center">
        <Container fluid>
          <div className="row">
            <div className="col-12">
              <a href="https://immobilienmakler-und-hausverwaltung.de/datenschutzerklaerung/" target="_blank" rel="noopener noreferrer">Datenschutz</a>
              <a href="https://immobilienmakler-und-hausverwaltung.de/impressum/" target="_blank" rel="noopener noreferrer">Impressum</a>
            </div>
          </div>
        </Container>
      </div>
    </footer>
  )
}

export default Footer
