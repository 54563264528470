import React, { useState } from 'react'
import { Link, graphql, useStaticQuery } from "gatsby"
import Navbar from 'react-bootstrap/Navbar'
import Image from 'gatsby-image'

const MainNav = ({ siteMap }) => {
  const [collapsed, setCollapsed] = useState(false);

  const { logo } = useStaticQuery(
    graphql`
      query {
        logo: file(relativePath: { eq: "logo.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 350, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <Navbar variant={null} expand="lg" className={`mainnav ${collapsed ? 'collapsed' : ''}`} onToggle={(value) => setCollapsed(value)}>
      <Navbar.Toggle className="navbar-toggler-right" data-target="#navbarMobile" aria-controls="navbarMobile">
        <span className="icon-bar top-bar"></span>
        <span className="icon-bar middle-bar"></span>
        <span className="icon-bar bottom-bar"></span>
      </Navbar.Toggle>

      <Link to="/" className="navbar-brand logo">
        <Image fluid={logo.childImageSharp.fluid} alt="Hanseatisches Immobilienkontor Schwolle &amp; Gienke GmbH &amp; Co. KG" />
      </Link>
      
      <div className="navbar-collapse d-none d-lg-flex justify-content-end">
        <ul className="nav navbar-nav">
          {siteMap.items.map((item, index) => (
            <li key={index} className="nav-item">
              <Link to={item.url} className={`nav-link ${item.prominent ? 'prominent' : 'navHover'} ${index === 0 ? 'pl-0' : ''}`}>{item.label}</Link>
            </li>
          ))}
        </ul>
      </div>

      <Navbar.Collapse bsPrefix="collapse" id="navbarMobile">
        <ul className="nav navbar-nav">
          {siteMap.items.map((item, index) => (
            <li key={index} className={`nav-item`}>
              <Link to={item.url} className={`nav-link ${item.prominent ? 'prominent' : 'navHover'} ${index === (siteMap.items.length - 1) ? '' : ''}`}>{item.label}</Link>
            </li>
          ))}
        </ul>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default MainNav