import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer"
import { ParallaxProvider } from 'react-scroll-parallax'
import CookieConsent, { Cookies } from "react-cookie-consent"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ children, siteMap }) => {
  
  const setCookies = () => {
    Cookies.set('gatsby-gdpr-google-analytics', true)
    Cookies.set('gatsby-gdpr-google-tagmanager', true)
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <ParallaxProvider>
          <div>
            <Header siteMap={siteMap} />

            {children}

            <Footer />
          </div>
          <CookieConsent
            location="bottom"
            buttonText="Akzeptieren"
            declineButtonText="Ablehnen"
            onAccept={() => setCookies()}>
              Diese Website nutzt Cookies, um bestmögliche Funktionalität bieten zu können. 
          </CookieConsent>
        </ParallaxProvider>
      )}
    />
  )
}

export default Layout
